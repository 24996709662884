
import React, { useState } from 'react';
import { FaRegCreditCard } from 'react-icons/fa';
import { MdOutlineAccessTime, MdDeliveryDining } from 'react-icons/md';
import { FaCoffee } from 'react-icons/fa';
import { FaCalendar } from "react-icons/fa";
import { FiDollarSign } from "react-icons/fi";
import { IoMdTime } from "react-icons/io";
import { useQuery } from '@tanstack/react-query';
import apis from '../services';

const OrderCard = ({ date, time, orderType, paymentMethod, itemName, itemPrice, itemQty, status, total, tax, deliveryCharges, grandTotal, order }) => {
    const [showAllProducts, setShowAllProducts] = useState(false);
    console.log("orderorder", order)
    const flattenedProducts = order?.product_ids?.flatMap(product => product.shop_product) || [];
    console.log("flattenedProducts", flattenedProducts);
    const visibleProducts = showAllProducts ? flattenedProducts : flattenedProducts.slice(0, 1);
    console.log("showall", showAllProducts)

    return (
        <div className={`neworder-card ${showAllProducts ? "expand" : ""}`}>
            <div className="neworder-header">
                <div className="neworder-logo">
                    <img src="/assets/images/orderIcon.png" alt="logo" className="neworder-logo" />
                </div>
                <div className="neworder-info">
                    <p className="neworder-id">Order Id  {order?.id}</p>
                    <p className="neworder-details">
                        <div className='new-or'><FaCalendar className="neworder-icon" /> </div>{order?.updated_at} &nbsp;
                        <div className='new-or'><IoMdTime className="neworder-icon" /></div> {order?.date} &nbsp;
                    </p>

                    <p className="neworder-details">
                        <div className='new-or'> <FiDollarSign className="neworder-icon" /> </div>{order?.payment_id} &nbsp;
                        <div className='new-or'> <MdDeliveryDining className="neworder-icon" /></div> Order Type: {order?.order_type}
                    </p>
                </div>
            </div>
            <div className="neworder-content">

                {flattenedProducts?.length > 0 && visibleProducts.map((singleProduct, index) => (
                    <div key={index} className="productlistcon">
                        <div className="neworder-item-details">
                            <img src="/assets/images/image-order.png" alt="item" className="neworder-item-image" />
                            <div className="neworder-item-info">
                                <p>{singleProduct.itemName}</p>
                                <span className="neworder-item-qty">Qty {order?.quantity}</span>
                            </div>
                            <p className="neworder-item-price">${singleProduct.price}</p>
                        </div>
                        <p className={`neworder-status ${order.status === 'Order Accepted' ? 'neworder-status-accepted' : ''}`}>
                            {singleProduct?.delivery_time}
                        </p>
                        <div className="neworder-summary">
                            <p className='totalAmount'>Total: <span>${order.total}</span></p>
                            <p>Tax: <span>${singleProduct.order_tax}</span></p>
                            <p>Delivery Charges: <span>${singleProduct?.delivery_cost}</span></p>
                        </div>
                    </div>
                ))}

                {flattenedProducts.length > 1 && (
                    <button
                        className="show-more-button"
                        onClick={() => setShowAllProducts(!showAllProducts)}
                    >
                        {showAllProducts ? "Show Less" : "Show More"}
                    </button>
                )}

                <p className="neworder-grand-total">Grand Total: <span>${order?.total_price}</span></p>
                {/* <button className="neworder-view-btn">VIEW ORDER</button> */}
            </div>
        </div>
    );
};

const Orders = () => {
    // const orders = [
    //     {
    //         date: 'Sept 15, 2024',
    //         time: '5:15 PM',
    //         orderType: 'Delivery',
    //         paymentMethod: 'Card',
    //         itemName: 'Flat White',
    //         itemPrice: '45.99',
    //         itemQty: 1,
    //         status: 'Order Accepted',
    //         total: '19.00',
    //         tax: '9.56',
    //         deliveryCharges: '4.30',
    //         grandTotal: '36.00',
    //     },
    //     {
    //         date: 'Sept 16, 2024',
    //         time: '3:00 PM',
    //         orderType: 'Pickup',
    //         paymentMethod: 'Cash',
    //         itemName: 'Cappuccino',
    //         itemPrice: '4.99',
    //         itemQty: 2,
    //         status: 'Order Preparing',
    //         total: '9.98',
    //         tax: '0.80',
    //         deliveryCharges: '0.00',
    //         grandTotal: '10.78',
    //     },
    //     {
    //         date: 'Sept 17, 2024',
    //         time: '1:45 PM',
    //         orderType: 'Delivery',
    //         paymentMethod: 'Card',
    //         itemName: 'Latte',
    //         itemPrice: '3.99',
    //         itemQty: 3,
    //         status: 'Order Delivered',
    //         total: '11.97',
    //         tax: '1.00',
    //         deliveryCharges: '3.50',
    //         grandTotal: '16.47',
    //     },
    //     {
    //         date: 'Sept 18, 2024',
    //         time: '11:00 AM',
    //         orderType: 'Delivery',
    //         paymentMethod: 'Card',
    //         itemName: 'Espresso',
    //         itemPrice: '2.99',
    //         itemQty: 1,
    //         status: 'Order Cancelled',
    //         total: '2.99',
    //         tax: '0.25',
    //         deliveryCharges: '0.00',
    //         grandTotal: '3.24',
    //     },
    // ];

    const {
        isLoading,
        error,
        refetch,
        data: { data: getOrderHistory } = {},
    } = useQuery({
        queryKey: ["getOrderHistory"],
        queryFn: () => apis.getOrderHistory(),
        onError: (error) => {
            console.error("Error geting Order History:", error);
        },
    });
    console.log("getOrderHistory", getOrderHistory);

    return (
        <div className="neworder-container">
            <p className="neworder-title">My Orders</p>
            <div className="neworder-list">
                {
                    getOrderHistory?.data?.length > 0 ?
                        getOrderHistory?.data?.reverse().map((order, index) => (
                            <OrderCard key={index} order={order} />
                        ))
                        :
                        <p>No Order Found</p>
                }
                {/* {orders.map((order, index) => (
          <OrderCard key={index} {...order} />
        ))} */}
            </div>
        </div>
    );
};

export default Orders;
