import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import apis from "../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { setCart } from "../redux/slice/productSlice";
import toast from "react-hot-toast";
import { ProductFields } from "../components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";
import LinkCustom from "../helper/Link";


const ProductDetail = () => {
  const { id } = useParams();
  let { user } = useSelector((store) => store.user);
  const [activeVariation, setActiveVariation] = useState("small");
  const [colorActive, setColorActive] = useState(false);
  const [count, setCount] = useState(1);
  const [mainImage, setMainImage] = useState(null);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [dataCart, setDataCart] = useState({
    product_id: "",
    quantity: 1,
    product_color: "",
    product_variation: "small",
  });
  const dispatch = useDispatch();

  // console.log("ID", id);

  //GET
  const {
    isLoading,
    error,
    refetch: refetchData,
    data: { data: getProductDetail } = {},
  } = useQuery({
    queryKey: ["productDetail"],
    queryFn: () => apis.getProductDetails(id, user?.id),
    onError: (error) => {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products. Please try again later.");
    },
  });

  useEffect(() => {
    refetchData();
    // SET MAIN IMAGE INITIALLY
    if (getProductDetail && getProductDetail.data.image.length > 0) {
      setMainImage(getProductDetail.data.image[0]);
    }
    setDataCart((previousData) => ({
      ...previousData,
      product_id: getProductDetail?.data?.id,
    }));
  }, [id, getProductDetail]);

  console.log("checkdataCart", dataCart)

  //POST
  const { mutate, isPending } = useMutation({
    mutationFn: apis.addToCart,
    onError: function ({ message }) {
      toast.error(message);
    },
    onSuccess: ({ data: addToCart, status }) => {
      console.log("DATA-USER-API-addToCart:", addToCart);
      if (status === 200) {
        dispatch(setCart(addToCart?.data));
        toast.success(t("responses.toast.addedToCart"));
      }
    },
  });

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
      setDataCart((previousData) => ({
        ...previousData,
        quantity: count - 1,
      }));
    }
  };

  const increment = () => {
    setCount(count + 1);
    setDataCart((previousData) => ({
      ...previousData,
      quantity: count + 1,
    }));
  };

  const handleCartSubmit = () => {
    if (!user) {
      return toast.error(t("responses.toast.errorLogin"), { id: 1 });
    }
    // if (!dataCart.product_color.trim()) {
    //   return toast.error("Please select a color", { id: 2 });
    // }
    console.log("DATACART:", dataCart);
    mutate(dataCart);
  };
  console.log("PRODUCT DETAIL DATAccc", getProductDetail);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleImageClick = (index) => {
    const clickedImage = getProductDetail?.data?.image[index];
    setMainImage(clickedImage);
  };
  const getAddVariation = (variation) => {
    setActiveVariation(variation);
    setDataCart((previousData) => ({
      ...previousData,
      product_variation: variation,
    }));
  };

  const { mutate: mutateFavouite, data: { data: favouriteProduct } = {} } =
    useMutation({
      mutationFn: apis.favouriteProduct,
      onError: function ({ message }) {
        toast.error(message);
      },
      onSuccess: ({ status, data }) => {
        refetchData();
        toast.success(data?.message);
        // setIsFavorite(favourite?.data?.product?.is_favourite);
      },
    });

  const handleFavourite = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("CLICK");
    // console.log("DATA ID", data.id);
    if (user) {
      mutateFavouite({
        product_id: Number(getProductDetail?.data?.id),
      });
    } else {
      return toast.error(t("responses.toast.errorLogin"), { id: 1 });
    }
  };

  const calculateTotalPrice = () => {
    let total = 0;

    // Add variant price
    if (activeVariation === "small") {
      total += parseFloat(getProductDetail?.data?.small_price);
    } else if (activeVariation === "medium") {
      total += parseFloat(getProductDetail?.data?.medium_price);
    } else if (activeVariation === "large") {
      total += parseFloat(getProductDetail?.data?.large_price);
    }

    // Multiply by quantity
    total *= count;
    console.log("totalll", total);

    return total.toFixed(2); // Return total bill with two decimal places
  };
  // useEffect(() => {
  //   console.log("RUNNN")
  //   calculateTotalPrice();
  // }, [activeVariation, count]);
  // useEffect(() => {
  //   if (
  //     getProductDetail?.data?.color &&
  //     getProductDetail.data.color.length > 0
  //   ) {
  //     setActiveColor(getProductDetail.data.color[0]);
  //   }
  // }, [getProductDetail?.data?.color]);
  const [activeColor, setActiveColor] = useState();
  const handleColorClick = (color) => {
    console.log("activeColoractiveColor", activeColor);
    // if (!color) {
    //   toast.error("Please select a color", { key: "1" });
    //   return;
    // }
    setActiveColor(color);
    setDataCart((previousData) => ({
      ...previousData,
      product_color: color,
    }));
  };

  const isValidUrl = (url) => {
    console.log("urlurl", url);
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };
  // console.log("activeColoractiveColoractiveColor", activeColor);
  return (
    <>
      {isLoading ? (
        <div className="loaderWrapper-cart">
          <div className="loader"></div>
        </div>
      ) : (
        <section className="product-details-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 p-0">
                <ul className="breadcrums">

                  <LinkCustom to="/">
                    <li>{t("titles.home")} -</li>
                  </LinkCustom>
                  <li>
                    {console.log(
                      getProductDetail?.data,
                      "getProductDetssssssssail"
                    )}
                    {detectLang(getProductDetail?.data, "name", lang)}
                    {/* {getProductDetail?.data?.name} */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 p-0 ">
                <div className="img-product-details">
                  {/* <div className="right" id="favoriteIconContainer">
                      <div className="right" onClick={handleFavourite}>
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5442 5.56959V6.36113C18.5323 6.4063 18.5156 6.45146 18.5133 6.499C18.4419 7.33094 18.1995 8.11773 17.8548 8.87361C17.2083 10.2927 16.2575 11.5002 15.1855 12.615C13.4764 14.393 11.5154 15.8548 9.45455 17.1931C9.36185 17.2525 9.30005 17.2406 9.21685 17.1859C7.4555 16.0402 5.76308 14.8066 4.23943 13.3542C3.19831 12.363 2.23801 11.3053 1.47737 10.0787C0.776157 8.95918 0.267481 7.77069 0.158139 6.43482C0.0369127 4.96822 0.429116 3.65374 1.36327 2.52229C3.24823 0.240382 6.54987 -0.087643 8.86743 1.76166C9.02907 1.89001 9.1812 2.03263 9.34521 2.17525C9.42127 2.10632 9.48545 2.04452 9.55201 1.98509C9.93232 1.63092 10.3531 1.33617 10.8237 1.11511C12.4614 0.344969 14.1016 0.366362 15.6965 1.20544C17.0419 1.91378 17.9285 3.02859 18.3397 4.49995C18.4372 4.84937 18.4776 5.21305 18.5465 5.57197L18.5442 5.56959Z"
                            // fill="#3E3E3E"
                            fill={
                              getProductDetail?.data?.is_favourite
                                ? "#E0201C"
                                : "#3E3E3E"
                            }
                          />
                        </svg>
                      </div>
                    </div> */}

                  <img src={mainImage} alt="productDetail" />
                </div>
                <div className="detail-img-slider">
                  <Swiper
                    ref={sliderRef}
                    //   spaceBetween={50}
                    slidesPerView={3}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    navigation={{
                      prevEl: ".swiper-button-prev",
                      nextEl: ".swiper-button-next",
                    }}
                  >
                    {getProductDetail?.data?.image?.map((data, index) => {
                      return (
                        <>
                          <SwiperSlide key={index}>
                            <div
                              className="img-con"
                              onClick={() => handleImageClick(index)}
                            >
                              <img src={data} alt="" />
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                  {getProductDetail?.data?.image?.length > 3 && (
                    <div className="detail-img-slider-button-parent">
                      <button className="home-slider-prev" onClick={handlePrev}>
                        <FaArrowLeft />
                      </button>
                      <button className="home-slider-next" onClick={handleNext}>
                        <FaArrowRight />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 p-0 right-main">
                <div className="product-details-d">
                  <div className="product-name-con">
                    <h2>
                      {detectLang(getProductDetail?.data, "name", lang)}
                      {/* {getProductDetail?.data?.name} */}
                    </h2>
                    <div className="right" id="favoriteIconContainer">
                      <div className="right" onClick={handleFavourite}>
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5442 5.56959V6.36113C18.5323 6.4063 18.5156 6.45146 18.5133 6.499C18.4419 7.33094 18.1995 8.11773 17.8548 8.87361C17.2083 10.2927 16.2575 11.5002 15.1855 12.615C13.4764 14.393 11.5154 15.8548 9.45455 17.1931C9.36185 17.2525 9.30005 17.2406 9.21685 17.1859C7.4555 16.0402 5.76308 14.8066 4.23943 13.3542C3.19831 12.363 2.23801 11.3053 1.47737 10.0787C0.776157 8.95918 0.267481 7.77069 0.158139 6.43482C0.0369127 4.96822 0.429116 3.65374 1.36327 2.52229C3.24823 0.240382 6.54987 -0.087643 8.86743 1.76166C9.02907 1.89001 9.1812 2.03263 9.34521 2.17525C9.42127 2.10632 9.48545 2.04452 9.55201 1.98509C9.93232 1.63092 10.3531 1.33617 10.8237 1.11511C12.4614 0.344969 14.1016 0.366362 15.6965 1.20544C17.0419 1.91378 17.9285 3.02859 18.3397 4.49995C18.4372 4.84937 18.4776 5.21305 18.5465 5.57197L18.5442 5.56959Z"
                            // fill="#3E3E3E"
                            fill={
                              getProductDetail?.data?.is_favourite
                                ? "#E0201C"
                                : "#3E3E3E"
                            }
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <p className="desc">
                    {detectLang(getProductDetail?.data, "description", lang)}
                    {/* {getProductDetail?.data?.description} */}
                  </p>
                  {getProductDetail?.data?.is_variant_check === 1 && (
                    <>
                      <div className="variation-item-con">
                        <div
                          className={`one ${activeVariation === "small" ? "active" : ""
                            }`}
                          onClick={() => getAddVariation("small")}
                        >
                          <p>{t("titles.small")}</p>
                          <p className="text-center">
                            ${(+getProductDetail?.data?.small_price).toFixed(0)}
                          </p>
                        </div>
                        <div
                          className={`one ${activeVariation === "medium" ? "active" : ""
                            }`}
                          onClick={() => getAddVariation("medium")}
                        >
                          <p>{t("titles.medium")}</p>
                          <p className="text-center">
                            $
                            {(+getProductDetail?.data?.medium_price).toFixed(0)}
                          </p>
                        </div>
                        <div
                          className={`one ${activeVariation === "large" ? "active" : ""
                            }`}
                          onClick={() => getAddVariation("large")}
                        >
                          <p>{t("titles.large")}</p>
                          <p className="text-center">
                            ${(+getProductDetail?.data?.large_price).toFixed(0)}
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="price">
                    <h2>
                    <div className="qwert">MX </div>
                      <span>
                        {getProductDetail?.data?.is_variant_check === 1
                          ? calculateTotalPrice()
                          : Number(getProductDetail?.data?.price).toFixed(2) *
                          count}
                        {/* {calculateTotalPrice()} */}
                        {/* {Number(getProductDetail?.data?.price).toFixed(2)}{" "} */}
                      </span>
                    </h2>
                    {/* <div className="down-price">
                    <img
                      src="/assets/images/product-detail-logo.png"
                      alt="product"
                    />
                    <p></p>
                  </div> */}
                  </div>
                  <div className="bulk-order-con">
                    <p>
                      {t("descriptions.bulkOrder")} <span>+00 000 0000000</span>
                    </p>
                  </div>
                  {/* <div className="socail-con">
                    <p>{t("titles.share")}</p>
                    <Link
                      to={
                        "https://www.instagram.com/javatimescaffe/?igshid=NmE0MzVhZDY%3D"
                      }
                      target="_blank"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.07344 0.408447H11.1207L12.304 0.474656C12.9907 0.497965 13.667 0.649489 14.2981 0.921449C15.0146 1.23116 15.6354 1.72652 16.0965 2.35634C16.5576 2.98617 16.8422 3.72764 16.921 4.50423C16.9707 4.8021 16.9872 5.09171 17.0203 5.38958V12.1828V12.4062C16.9707 12.7785 16.9541 13.1508 16.8796 13.5149C16.7553 14.2733 16.4304 14.9847 15.9387 15.5753C15.4469 16.166 14.8062 16.6144 14.0829 16.8743C13.3429 17.1404 12.5607 17.2694 11.7744 17.2549C9.98717 17.2549 8.19993 17.2549 6.41269 17.2549C5.53536 17.284 4.65767 17.209 3.79801 17.0315C3.13359 16.8948 2.51079 16.6033 1.98026 16.1806C1.44973 15.7579 1.02644 15.2159 0.744799 14.5988C0.392258 13.7935 0.219994 12.9209 0.240067 12.0421C0.240067 10.2217 0.198696 8.40141 0.240067 6.5728C0.237221 5.80572 0.292539 5.03952 0.40555 4.2808C0.478666 3.70894 0.667506 3.15798 0.960557 2.6615C1.25361 2.16502 1.64472 1.73344 2.11005 1.39309C2.93276 0.811273 3.90794 0.483347 4.91503 0.449834L6.07344 0.408447ZM8.62192 15.7986C9.06873 15.7986 9.51554 15.7986 9.95408 15.7986C10.9139 15.7986 11.8737 15.7986 12.817 15.6579C13.4145 15.6132 13.9809 15.3736 14.4293 14.9761C14.8776 14.5785 15.1833 14.0449 15.2993 13.457C15.4256 12.9146 15.4867 12.359 15.4813 11.8021C15.4813 10.1473 15.4813 8.40967 15.4813 6.71344C15.4856 6.02744 15.4441 5.34193 15.3572 4.66144C15.3055 4.03505 15.045 3.44413 14.6174 2.9835C14.1898 2.52288 13.6198 2.21922 12.999 2.12123C12.4173 2.0162 11.8277 1.96083 11.2366 1.95573C9.75548 1.95573 8.28267 1.95573 6.80158 1.95573C5.95539 1.92943 5.10847 1.97371 4.26964 2.08813C3.67381 2.16955 3.11909 2.4376 2.68503 2.85381C2.25097 3.27003 1.95989 3.81305 1.85355 4.40494C1.74998 4.95064 1.69461 5.50439 1.68807 6.05979C1.68807 7.41678 1.68807 8.76548 1.68807 10.1142C1.68807 11.074 1.72944 12.0338 1.82046 12.9771C1.84966 13.4249 1.98684 13.859 2.22026 14.2423C2.45368 14.6255 2.77641 14.9466 3.16089 15.178C3.70769 15.5011 4.32964 15.6751 4.96469 15.6828C6.19755 15.749 7.4056 15.7655 8.62192 15.8234V15.7986Z"
                          fill="#8E8E8E"
                        />
                        <path
                          d="M4.27832 8.8482C4.27832 8.27813 4.39074 7.71365 4.60915 7.18708C4.82756 6.66051 5.14765 6.18215 5.55114 5.77943C5.95463 5.37672 6.43358 5.05752 6.96057 4.84012C7.48756 4.62271 8.05225 4.51137 8.62232 4.51246C9.77298 4.51684 10.8749 4.9776 11.6862 5.79357C12.4975 6.60955 12.952 7.71407 12.9498 8.86474C12.9454 10.0118 12.4867 11.1103 11.674 11.9199C10.8614 12.7294 9.76108 13.1839 8.61404 13.1839C7.46413 13.1839 6.36133 12.7271 5.54822 11.914C4.73512 11.1009 4.27832 9.9981 4.27832 8.8482ZM5.80905 8.8482C5.80687 9.21686 5.8776 9.58229 6.01717 9.92352C6.15674 10.2647 6.3624 10.575 6.62232 10.8365C6.88223 11.0979 7.19128 11.3054 7.53167 11.447C7.87207 11.5886 8.2371 11.6615 8.60577 11.6614C9.35046 11.6637 10.0657 11.3705 10.5946 10.8462C11.1235 10.322 11.4229 9.60942 11.4273 8.86474C11.4295 8.4943 11.3583 8.12709 11.2178 7.78432C11.0773 7.44155 10.8703 7.13001 10.6087 6.86768C10.3471 6.60535 10.0362 6.39743 9.69384 6.25592C9.35148 6.11441 8.98448 6.04211 8.61404 6.04321C8.24537 6.04211 7.88013 6.11393 7.53932 6.25451C7.19851 6.39508 6.88887 6.60164 6.62819 6.86233C6.3675 7.12301 6.16093 7.43268 6.02036 7.77349C5.87978 8.1143 5.80796 8.47954 5.80905 8.8482Z"
                          fill="#8E8E8E"
                        />
                        <path
                          d="M13.1401 3.33747C13.2737 3.3385 13.4058 3.36627 13.5285 3.41913C13.6512 3.472 13.7621 3.54889 13.8546 3.64528C13.9471 3.74167 14.0194 3.85559 14.0673 3.98036C14.1151 4.10513 14.1374 4.23821 14.133 4.37176C14.1243 4.63879 14.011 4.89172 13.8175 5.07593C13.624 5.26015 13.3658 5.36088 13.0987 5.3564C12.9654 5.35319 12.8341 5.3236 12.7123 5.26936C12.5905 5.21511 12.4806 5.13729 12.3891 5.04035C12.2975 4.94342 12.2261 4.82929 12.1789 4.70459C12.1317 4.57989 12.1097 4.44709 12.1141 4.31383C12.1162 4.182 12.1445 4.05192 12.1975 3.93117C12.2504 3.81043 12.3269 3.70142 12.4224 3.61054C12.5179 3.51965 12.6305 3.44869 12.7538 3.40181C12.877 3.35493 13.0083 3.33306 13.1401 3.33747Z"
                          fill="#8E8E8E"
                        />
                      </svg>
                    </Link>
                    <Link
                      to={
                        "https://www.facebook.com/JavaTimesCaffe?mibextid=ZbWKwL"
                      }
                      target="_blank"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.18335"
                          y="1.27869"
                          width="14.8369"
                          height="14.8369"
                          rx="5"
                          stroke="#8E8E8E"
                          strokeWidth="2"
                        />
                        <path
                          d="M9.81077 4.30524H10.2842H10.6062V5.72138H9.54561C9.46377 5.71632 9.38171 5.72757 9.30473 5.75436C9.22775 5.78116 9.15755 5.82291 9.09867 5.87696C9.03979 5.931 8.99353 5.99615 8.96286 6.06815C8.93219 6.14015 8.9178 6.21742 8.92062 6.29501C8.92062 6.68937 8.92062 7.08374 8.92062 7.4781H10.6062L10.3979 9.05556H8.9585V13.1067H7.23504V9.05556H5.79565V7.4781H7.23504V7.37055C7.23504 6.95827 7.23504 6.54597 7.23504 6.13369C7.23331 5.64152 7.4297 5.16717 7.78427 4.80716C8.05928 4.53682 8.41699 4.35504 8.80699 4.28735H9.12896L9.81077 4.30524Z"
                          fill="#8E8E8E"
                        />
                      </svg>
                    </Link>
                    <Link
                      to={"https://www.tiktok.com/@antonioleite9"}
                      target="_blank"
                    >
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.22168"
                          y="1.13318"
                          width="14.8369"
                          height="14.8369"
                          rx="5"
                          stroke="#8E8E8E"
                          strokeWidth="2"
                        />
                        <path
                          d="M7.97777 7.82393V9.37895H7.43801C7.11571 9.38875 6.80594 9.50602 6.55794 9.7121C6.30995 9.91818 6.13796 10.2012 6.06932 10.5163C6.02732 10.7085 6.02447 10.9073 6.06094 11.1006C6.09741 11.294 6.17246 11.478 6.28161 11.6417C6.39076 11.8054 6.53177 11.9455 6.69622 12.0535C6.86068 12.1616 7.04521 12.2354 7.23881 12.2705C7.43589 12.3051 7.63799 12.2988 7.83252 12.2519C8.02705 12.205 8.20986 12.1187 8.36958 11.9981C8.52929 11.8776 8.6625 11.7255 8.76091 11.5512C8.85932 11.377 8.92084 11.1844 8.94163 10.9854C8.94163 10.889 8.94163 10.7862 8.94163 10.6898V4.14197H10.4774C10.7087 5.81909 11.5248 6.41669 12.977 6.59661V8.13878C12.5382 8.13956 12.1028 8.06118 11.6918 7.90745C11.287 7.7468 10.9015 7.54761 10.5031 7.36769V10.7798C10.5141 11.399 10.3353 12.0068 9.99072 12.5215C9.6461 13.0361 9.15218 13.4329 8.57536 13.6585C8.14252 13.8189 7.67868 13.878 7.21946 13.8311C6.76023 13.7843 6.31785 13.6328 5.92631 13.3883C5.53477 13.1439 5.20447 12.8129 4.96081 12.4208C4.71714 12.0288 4.56659 11.5861 4.52072 11.1268C4.47984 10.7136 4.52495 10.2965 4.65321 9.90161C4.78146 9.50675 4.99009 9.14273 5.26594 8.83246C5.5418 8.5222 5.87892 8.27241 6.25605 8.09884C6.63319 7.92526 7.04219 7.83167 7.45728 7.82393H7.97777Z"
                          fill="#8E8E8E"
                        />
                      </svg>
                    </Link>
                  </div> */}
                  {console.log(
                    "getProductDetail?.data?.color?.length",
                    getProductDetail
                  )}
                  {getProductDetail?.data?.color?.[0] != "" && (
                    <>
                      <div className="color-con-main">
                        <p>{t("titles.color")}</p>
                        <div className="color-con">
                          {getProductDetail?.data?.color?.map(
                            (color, index) => {
                              return (
                                <>
                                  {console.log("colorrr", color)}
                                  <div
                                    key={index}
                                    className={`color-option ${activeColor == color && color != "Black"
                                        ? "active-color"
                                        : activeColor == "Black" &&
                                          color == "Black"
                                          ? "black-active"
                                          : ""
                                      }`}
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      borderRadius: "100px",
                                      backgroundColor: color.toLowerCase(),
                                      cursor: "pointer",
                                      // border: color.toLowerCase() === "black" ? "10px solid red" : "10px solid yellow"
                                      // border: color.toLowerCase() === "black" ? "2px solid black" : "none",
                                    }}
                                    onClick={() => handleColorClick(color)}
                                  ></div>
                                </>
                              );
                            }
                          )}
                          {/* <div
                      className={`${colorActive ? "active-color" : ""} red`}
                      onClick={() => setColorActive(true)}
                    />
                    <div className="blue" />
                    <div className="orange" /> */}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="quantity-con">
                    <p> {t("titles.quantity")} </p>
                    <div className="counter">
                      <button className="btn-1" onClick={decrement}>
                        <svg
                          width="12"
                          height="3"
                          viewBox="0 0 12 3"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.16 0.875555L0.16 2.14993C0.16 2.39262 0.356737 2.58936 0.599424 2.58936L11.2406 2.58935C11.4833 2.58935 11.68 2.39262 11.68 2.14993L11.68 0.875555C11.68 0.632867 11.4833 0.43613 11.2406 0.43613L0.599424 0.436131C0.356737 0.436131 0.16 0.632868 0.16 0.875555Z"
                            fill="#3E3E3E"
                          />
                        </svg>
                      </button>
                      <p id="counterValue">{count}</p>
                      <button className="btn-2" onClick={increment}>
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.401 5.48358H7.15693V1.23951C7.15693 0.996708 6.96027 0.800049 6.71747 0.800049H5.44344C5.20064 0.800049 5.00398 0.996708 5.00398 1.23951V5.48358H0.759466C0.516666 5.48358 0.320007 5.68024 0.320007 5.92304V7.19706C0.320007 7.43986 0.516666 7.63652 0.759466 7.63652H5.00354V11.8806C5.00354 12.1234 5.20019 12.32 5.44299 12.32H6.71702C6.95982 12.32 7.15648 12.1234 7.15648 11.8806V7.63652H11.4005C11.6433 7.63652 11.84 7.43986 11.84 7.19706V5.92304C11.84 5.68024 11.6433 5.48358 11.4005 5.48358H11.401Z"
                            fill="#3E3E3E"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {user ? (
                    ""
                  ) : (
                    <div className="login-con">
                      <p>
                        <span className="member-span">
                          {t("descriptions.jtcMember")}
                        </span>
                        <Link to={"/login"} className="login-span">
                          {t("buttons.login")}
                        </Link>{" "}
                        {t("descriptions.productDetailDesc2")}
                      </p>
                    </div>
                  )}
 <div className="socail-con">
                    <p>{t("titles.share")}</p>
                    <Link
                      to={
                        "https://www.instagram.com/javatimescaffe/?igshid=NmE0MzVhZDY%3D"
                      }
                      target="_blank"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.07344 0.408447H11.1207L12.304 0.474656C12.9907 0.497965 13.667 0.649489 14.2981 0.921449C15.0146 1.23116 15.6354 1.72652 16.0965 2.35634C16.5576 2.98617 16.8422 3.72764 16.921 4.50423C16.9707 4.8021 16.9872 5.09171 17.0203 5.38958V12.1828V12.4062C16.9707 12.7785 16.9541 13.1508 16.8796 13.5149C16.7553 14.2733 16.4304 14.9847 15.9387 15.5753C15.4469 16.166 14.8062 16.6144 14.0829 16.8743C13.3429 17.1404 12.5607 17.2694 11.7744 17.2549C9.98717 17.2549 8.19993 17.2549 6.41269 17.2549C5.53536 17.284 4.65767 17.209 3.79801 17.0315C3.13359 16.8948 2.51079 16.6033 1.98026 16.1806C1.44973 15.7579 1.02644 15.2159 0.744799 14.5988C0.392258 13.7935 0.219994 12.9209 0.240067 12.0421C0.240067 10.2217 0.198696 8.40141 0.240067 6.5728C0.237221 5.80572 0.292539 5.03952 0.40555 4.2808C0.478666 3.70894 0.667506 3.15798 0.960557 2.6615C1.25361 2.16502 1.64472 1.73344 2.11005 1.39309C2.93276 0.811273 3.90794 0.483347 4.91503 0.449834L6.07344 0.408447ZM8.62192 15.7986C9.06873 15.7986 9.51554 15.7986 9.95408 15.7986C10.9139 15.7986 11.8737 15.7986 12.817 15.6579C13.4145 15.6132 13.9809 15.3736 14.4293 14.9761C14.8776 14.5785 15.1833 14.0449 15.2993 13.457C15.4256 12.9146 15.4867 12.359 15.4813 11.8021C15.4813 10.1473 15.4813 8.40967 15.4813 6.71344C15.4856 6.02744 15.4441 5.34193 15.3572 4.66144C15.3055 4.03505 15.045 3.44413 14.6174 2.9835C14.1898 2.52288 13.6198 2.21922 12.999 2.12123C12.4173 2.0162 11.8277 1.96083 11.2366 1.95573C9.75548 1.95573 8.28267 1.95573 6.80158 1.95573C5.95539 1.92943 5.10847 1.97371 4.26964 2.08813C3.67381 2.16955 3.11909 2.4376 2.68503 2.85381C2.25097 3.27003 1.95989 3.81305 1.85355 4.40494C1.74998 4.95064 1.69461 5.50439 1.68807 6.05979C1.68807 7.41678 1.68807 8.76548 1.68807 10.1142C1.68807 11.074 1.72944 12.0338 1.82046 12.9771C1.84966 13.4249 1.98684 13.859 2.22026 14.2423C2.45368 14.6255 2.77641 14.9466 3.16089 15.178C3.70769 15.5011 4.32964 15.6751 4.96469 15.6828C6.19755 15.749 7.4056 15.7655 8.62192 15.8234V15.7986Z"
                          fill="#8E8E8E"
                        />
                        <path
                          d="M4.27832 8.8482C4.27832 8.27813 4.39074 7.71365 4.60915 7.18708C4.82756 6.66051 5.14765 6.18215 5.55114 5.77943C5.95463 5.37672 6.43358 5.05752 6.96057 4.84012C7.48756 4.62271 8.05225 4.51137 8.62232 4.51246C9.77298 4.51684 10.8749 4.9776 11.6862 5.79357C12.4975 6.60955 12.952 7.71407 12.9498 8.86474C12.9454 10.0118 12.4867 11.1103 11.674 11.9199C10.8614 12.7294 9.76108 13.1839 8.61404 13.1839C7.46413 13.1839 6.36133 12.7271 5.54822 11.914C4.73512 11.1009 4.27832 9.9981 4.27832 8.8482ZM5.80905 8.8482C5.80687 9.21686 5.8776 9.58229 6.01717 9.92352C6.15674 10.2647 6.3624 10.575 6.62232 10.8365C6.88223 11.0979 7.19128 11.3054 7.53167 11.447C7.87207 11.5886 8.2371 11.6615 8.60577 11.6614C9.35046 11.6637 10.0657 11.3705 10.5946 10.8462C11.1235 10.322 11.4229 9.60942 11.4273 8.86474C11.4295 8.4943 11.3583 8.12709 11.2178 7.78432C11.0773 7.44155 10.8703 7.13001 10.6087 6.86768C10.3471 6.60535 10.0362 6.39743 9.69384 6.25592C9.35148 6.11441 8.98448 6.04211 8.61404 6.04321C8.24537 6.04211 7.88013 6.11393 7.53932 6.25451C7.19851 6.39508 6.88887 6.60164 6.62819 6.86233C6.3675 7.12301 6.16093 7.43268 6.02036 7.77349C5.87978 8.1143 5.80796 8.47954 5.80905 8.8482Z"
                          fill="#8E8E8E"
                        />
                        <path
                          d="M13.1401 3.33747C13.2737 3.3385 13.4058 3.36627 13.5285 3.41913C13.6512 3.472 13.7621 3.54889 13.8546 3.64528C13.9471 3.74167 14.0194 3.85559 14.0673 3.98036C14.1151 4.10513 14.1374 4.23821 14.133 4.37176C14.1243 4.63879 14.011 4.89172 13.8175 5.07593C13.624 5.26015 13.3658 5.36088 13.0987 5.3564C12.9654 5.35319 12.8341 5.3236 12.7123 5.26936C12.5905 5.21511 12.4806 5.13729 12.3891 5.04035C12.2975 4.94342 12.2261 4.82929 12.1789 4.70459C12.1317 4.57989 12.1097 4.44709 12.1141 4.31383C12.1162 4.182 12.1445 4.05192 12.1975 3.93117C12.2504 3.81043 12.3269 3.70142 12.4224 3.61054C12.5179 3.51965 12.6305 3.44869 12.7538 3.40181C12.877 3.35493 13.0083 3.33306 13.1401 3.33747Z"
                          fill="#8E8E8E"
                        />
                      </svg>
                    </Link>
                    <Link
                      to={
                        "https://www.facebook.com/JavaTimesCaffe?mibextid=ZbWKwL"
                      }
                      target="_blank"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.18335"
                          y="1.27869"
                          width="14.8369"
                          height="14.8369"
                          rx="5"
                          stroke="#8E8E8E"
                          strokeWidth="2"
                        />
                        <path
                          d="M9.81077 4.30524H10.2842H10.6062V5.72138H9.54561C9.46377 5.71632 9.38171 5.72757 9.30473 5.75436C9.22775 5.78116 9.15755 5.82291 9.09867 5.87696C9.03979 5.931 8.99353 5.99615 8.96286 6.06815C8.93219 6.14015 8.9178 6.21742 8.92062 6.29501C8.92062 6.68937 8.92062 7.08374 8.92062 7.4781H10.6062L10.3979 9.05556H8.9585V13.1067H7.23504V9.05556H5.79565V7.4781H7.23504V7.37055C7.23504 6.95827 7.23504 6.54597 7.23504 6.13369C7.23331 5.64152 7.4297 5.16717 7.78427 4.80716C8.05928 4.53682 8.41699 4.35504 8.80699 4.28735H9.12896L9.81077 4.30524Z"
                          fill="#8E8E8E"
                        />
                      </svg>
                    </Link>
                    <Link
                      to={"https://www.tiktok.com/@antonioleite9"}
                      target="_blank"
                    >
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.22168"
                          y="1.13318"
                          width="14.8369"
                          height="14.8369"
                          rx="5"
                          stroke="#8E8E8E"
                          strokeWidth="2"
                        />
                        <path
                          d="M7.97777 7.82393V9.37895H7.43801C7.11571 9.38875 6.80594 9.50602 6.55794 9.7121C6.30995 9.91818 6.13796 10.2012 6.06932 10.5163C6.02732 10.7085 6.02447 10.9073 6.06094 11.1006C6.09741 11.294 6.17246 11.478 6.28161 11.6417C6.39076 11.8054 6.53177 11.9455 6.69622 12.0535C6.86068 12.1616 7.04521 12.2354 7.23881 12.2705C7.43589 12.3051 7.63799 12.2988 7.83252 12.2519C8.02705 12.205 8.20986 12.1187 8.36958 11.9981C8.52929 11.8776 8.6625 11.7255 8.76091 11.5512C8.85932 11.377 8.92084 11.1844 8.94163 10.9854C8.94163 10.889 8.94163 10.7862 8.94163 10.6898V4.14197H10.4774C10.7087 5.81909 11.5248 6.41669 12.977 6.59661V8.13878C12.5382 8.13956 12.1028 8.06118 11.6918 7.90745C11.287 7.7468 10.9015 7.54761 10.5031 7.36769V10.7798C10.5141 11.399 10.3353 12.0068 9.99072 12.5215C9.6461 13.0361 9.15218 13.4329 8.57536 13.6585C8.14252 13.8189 7.67868 13.878 7.21946 13.8311C6.76023 13.7843 6.31785 13.6328 5.92631 13.3883C5.53477 13.1439 5.20447 12.8129 4.96081 12.4208C4.71714 12.0288 4.56659 11.5861 4.52072 11.1268C4.47984 10.7136 4.52495 10.2965 4.65321 9.90161C4.78146 9.50675 4.99009 9.14273 5.26594 8.83246C5.5418 8.5222 5.87892 8.27241 6.25605 8.09884C6.63319 7.92526 7.04219 7.83167 7.45728 7.82393H7.97777Z"
                          fill="#8E8E8E"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div className="product-detail-cart">
                    <button
                      className="fancy-button"
                      type="button"
                      onClick={handleCartSubmit}
                      disabled={isLoading || isPending}
                    >
                      {isPending ? t("buttons.adding") : t("buttons.addToCart")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-specification">
              <p className="main-heading">
                {detectLang(getProductDetail?.data, "name", lang)}
                {/* {getProductDetail?.data?.name}  */}
                {/* {t("titles.specification")} */}
              </p>
              <div className="row">
                <ProductFields
                  heading={t("specification.model_number")}
                  value={getProductDetail?.data?.model_number}
                />
                <ProductFields
                  heading={t("specification.brand_Name")}
                  value={getProductDetail?.data?.brand_name}
                />
                {/* Uncomment and modify similar lines for other specifications */}
                {getProductDetail?.data?.capacity !== "null" && (
                  <ProductFields
                    heading={t("specification.capacity")}
                    value={getProductDetail?.data?.capacity}
                  />
                )}
                <ProductFields
                  heading={t("specification.brewingMethod")}
                  value={getProductDetail?.data?.brewing_method}
                />
                <ProductFields
                  heading={t("specification.feature")}
                  value={getProductDetail?.data?.features}
                />
                {getProductDetail?.data?.height !== "null" && (
                  <ProductFields
                    heading={t("specification.height")}
                    value={getProductDetail?.data?.height}
                  />
                )}
                {getProductDetail?.data?.width !== "null" && (
                  <ProductFields
                    heading={t("specification.width")}
                    value={getProductDetail?.data?.width}
                  />
                )}
                {getProductDetail?.data?.depth !== "null" && (
                  <ProductFields
                    heading={t("specification.depth")}
                    value={getProductDetail?.data?.depth}
                  />
                )}
                <ProductFields
                  heading={t("specification.weight")}
                  value={getProductDetail?.data?.weight}
                />
                <ProductFields
                  heading={t("specification.power")}
                  value={getProductDetail?.data?.power}
                />
                <ProductFields
                  heading={t("specification.accessories")}
                  value={getProductDetail?.data?.accessories}
                />
                <ProductFields
                  heading={t("specification.warranty")}
                  value={getProductDetail?.data?.warranty}
                />
                <ProductFields
                  heading={t("specification.voltage")}
                  value={getProductDetail?.data?.voltage}
                />
                <ProductFields
                  heading={t("specification.price")}
                  value={
                    getProductDetail?.data?.price !== undefined
                      ? parseFloat(getProductDetail.data.price).toFixed(2)
                      : ""
                  }
                />
                <ProductFields
                  heading={t("specification.deliveryTime")}
                  value={getProductDetail?.data?.delivery_time}
                />
                <ProductFields
                  heading={t("specification.deliveryCost")}
                  value={getProductDetail?.data?.delivery_cost}
                />
                <ProductFields
                  heading={t("specification.roastLevel")}
                  value={getProductDetail?.data?.roast_level}
                />
                <ProductFields
                  heading={t("specification.processingMethod")}
                  value={getProductDetail?.data?.processing_method}
                />
                <ProductFields
                  heading={t("specification.flavorNotes")}
                  value={getProductDetail?.data?.flavor_notes}
                />
                <ProductFields
                  heading={t("specification.intensity")}
                  value={getProductDetail?.data?.intensity}
                />
                <ProductFields
                  heading={t("specification.grindType")}
                  value={getProductDetail?.data?.grind_type}
                />
                <ProductFields
                  heading={t("specification.packagingSize")}
                  value={getProductDetail?.data?.packaging_size}
                />
                <ProductFields
                  heading={t("specification.harvestDate")}
                  value={getProductDetail?.data?.harvest_date}
                />
                <ProductFields
                  heading={t("specification.bestBefore")}
                  value={getProductDetail?.data?.best_before}
                />
                <ProductFields
                  heading={t("specification.material")}
                  value={getProductDetail?.data?.material}
                />
              </div>
            </div>

            {/* <div className="row"> */}
            {console.log(
              "getProductDetail?.data?.video_link",
              getProductDetail?.data?.video_link
            )}
            {isValidUrl(getProductDetail?.data?.video_link) && (
              <>
                <div className="video-box">
                  {/* <video
            src={
              getProductDetail?.data?.video_link ||
              "/assets/videos/video1.mp4"
            }
            type="mp4"
            autoPlay
            loop
            muted
          ></video> */}
                  <iframe
                    style={{
                      // pointerEvents: "none",
                      background: "#333333",
                      width: "100%",
                      height: "80vh",
                      borderRadius: "6px",
                    }}
                    className=" login-youtube-video"
                    width="100%"
                    height="100%"
                    src={getProductDetail?.data?.video_link}
                    title="YouTube video player"
                    frameborder=""
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </>
            )}
          </div>
          {/* </div> */}
        </section>
      )}
    </>
  );
};

export default ProductDetail;
