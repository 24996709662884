import React, { Suspense, useEffect, useState } from "react";
import {
  Category,
  Footer,
  Header,
  ScrollToTop,
  // SubCategory,
} from "./components";
import {
  Home,
  Order,
  ProductDetail,
  Profile,
  Notification,
  Favorite,
  Cart,
  Checkout,
  ViewAllProducts,
  PageLoader,
  Language,
} from "../src/screens/index";
//STYLE
import "./assets/css/style.css";
import "./App.css";
import "./assets/css/responsive.css";
import "react-phone-input-2/lib/style.css";

import NewOrder from "./screens/NewOrder";

import { Route, Routes } from "react-router-dom";
import {
  ChangePassword,
  ConfirmPassword,
  ForgotPassword,
  Login,
  SignUp,
} from "./components/Auth";
import { useDispatch, useSelector } from "react-redux";
import reduxApis from "./redux/api";

const App = () => {
  //SET PRODUCT DATA IN STORE
  let { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const search = "";
  const fetchDataGetAllProducts = () => {
    dispatch(
      reduxApis.getAllProductWithCategory({
        userId: user?.id,
        sort_by: "0",
        filterMin: "",
        filterMax: "",
      })
    );
  };
  useEffect(() => {
    fetchDataGetAllProducts();
  }, []);

  return (
    <Suspense fallback={<PageLoader />} >
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Language />}>
          <Route path=":lang">
            <Route index element={<Home />} />
            <Route path="*" element={<Home />} />

            <Route path="category/:categoryID" element={<Category />} />
            {/* <Route path="subcategory/:subcategoryID" element={<SubCategory />} /> */}

            <Route path="product/:id" element={<ProductDetail />} />
            <Route path="viewall/:id" element={<ViewAllProducts />} />
            <Route path="order" element={<NewOrder />} />
            <Route path="notification" element={<Notification />} />
            <Route path="favorite" element={<Favorite />} />
            <Route path="cart" element={<Cart />} />
            <Route path="profile" element={<Profile />} />
            <Route path="checkout" element={<Checkout />} />

            {/* AUTH ROUTES */}
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="confirm-password" element={<ConfirmPassword />} />

            {/* ///////////////New Order//////// */}
{/* <Route path="neworder" element={<NewOrder/>}/> */}

          </Route>
        </Route>
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default App;
